import store from "../store";
import {getPanelUuid, getPreference} from "./apiFunctions"
export const clearCookies = () => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie =
        // name.trim() + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;";
        name.trim() + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=simplifymycareer.com;";
    }
  };

  export const checkForAccess = (project) => {
    if (document.cookie.length > 0) {
      let data = document.cookie.split(";");
      for (let i in data) {
        let cur = data[i].split("=");
        if (cur[0].trim() === project && cur[1] !== 'no-access') {
          return JSON.parse(cur[1]);
        }
      }
    }
    return null;
  }

  export const getloginData = () => {
    if (document.cookie.length > 0) {
      let data = document.cookie.split(";");
      for (let i in data) {
        let cur = data[i].split("=");
        if (cur[0].trim() === "loginData") {
          return JSON.parse(cur[1]);
        }
      }
    }
    return null;
  }

  export const getToken = () => {
    if (document.cookie.length > 0) {
      let data = document.cookie.split(";");
      for (let i in data) {
        let cur = data[i].split("=");
        if (cur[0].trim() === "accessToken") {
          return cur[1];
        }
      }
    }
    return null;
  };

  export const getRefreshToken = () => {
    if (document.cookie.length > 0) {
      let data = document.cookie.split(";");
      for (let i in data) {
        let cur = data[i].split("=");
        if (cur[0].trim() === "refreshToken") {
          return cur[1];
        }
      }
    }
    return null;
  };

  export const getAccessProjects = () => {
    let projects = []
    if(checkForAccess('recruitment')){
      projects.push('recruitment');
    }
    if(checkForAccess('assessment')){
      projects.push('assessment');
    }
    return projects;
  }

  export const checkForPanelAccess = async (uuid, next) => {
        const res = await getPanelUuid(uuid);
        if (res.status == 200) {
          let data = {
            value : 'recruitment',
            panelId : uuid
          }
          store.dispatch('userVUEX/goToPage', data)
        }
        else{
          console.log('no access to feedback link');
          gotoLogin(next);
        }
  }

  export const getPreferenceData = async (isLaptop, next) => {
    const res = await getPreference();
    if (res.status == 200) {
      if(res.data.data.defaultModule && isLaptop){
      store.dispatch('userVUEX/goToPage', res.data.data.defaultModule)
      }
      else if(isLaptop && getAccessProjects().length !== 0){
        store.dispatch('userVUEX/goToPage', getAccessProjects()[0])
      }
      else{
        gotoLogin(next);
      }
    }
    else{
      if(getAccessProjects().length !== 0){
        store.dispatch('userVUEX/goToPage', getAccessProjects()[0])
      }
      else{
        gotoLogin(next);
      }
    }
}
  
  const checkForPanelLogin = (next) => {
      let recData = checkForAccess('recruitment');
      if(recData.userPermissions && recData.userPermissions.isActive && recData.roleName === 'Panelist'){
        store.dispatch('userVUEX/goToPage', 'candidate')
      }
      else{
        gotoLogin(next);
      }
  }

  export const checkIfLoggedIn = (to, isLaptop, next) => {
    if (document.cookie.length != 0 && getToken() && getRefreshToken()) {
      let projects = ['assessment', 'training', 'recruitment']
      if(to.query.redirectTo && to.query.courseID){
        store.commit('userVUEX/setTrainingRedirect', { redirect : to.query.redirectTo, courseID : to.query.courseID })
      }
      else{
        store.commit('userVUEX/setTrainingRedirect', {});
      }
      if(!to.query.from){
        if(checkForAccess('candidate')){
          store.dispatch('userVUEX/goToPage', 'candidate')
        }
        else if(getAccessProjects().length !== 0)
          {
              if(getAccessProjects().length === 1){
                if((getAccessProjects()[0] === 'assessment' || getAccessProjects()[0] === 'recruitment') && isLaptop){
                  console.log('here');
                  store.dispatch('userVUEX/goToPage', getAccessProjects()[0])
                }
                else if(getAccessProjects()[0] === 'recruitment' && !isLaptop){
                    checkForPanelLogin(next);
                }
                else{
                  gotoLogin(next);
                }
              }
              else{
                  getPreferenceData(isLaptop, next);
              }
          }
          else{
            gotoLogin(next);
        }
      }
      else if(projects.includes(to.query.from)){
        if(checkForAccess(to.query.from)){
          if(to.query.from === 'recruitment' && to.query.uuid && to.query.panel){
             checkForPanelAccess(to.query.uuid, next)
          }
          else if(isLaptop){
            store.dispatch('userVUEX/goToPage', to.query.from);
          }
          else if(to.query.from === 'recruitment' && !isLaptop){
            checkForPanelLogin(next)
          }
          else{
            gotoLogin(next);
          }
        }
        else{
          gotoLogin(next);
        }
      }
    }
    else{
      gotoLogin(next);
    }
  }

  const gotoLogin = (next) =>{
    clearCookies();
    next()
  }

